// _components/footer.tsx
import React from 'react';

interface FooterProps {
  className?: string;
}

const Footer: React.FC<FooterProps> = ({ className }) => {
  return (
    <footer className={`bg-primary text-primary-foreground py-6 ${className} fixed bottom-0 w-full`}>
      <div className="container mx-auto text-center">
        <p>© 2024 Qualia Travel Management. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
