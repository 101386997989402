import { ClerkLoading, SignedIn, SignedOut, SignInButton, SignOutButton, SignUpButton } from '@clerk/nextjs';
import { Button, Navbar, NavbarBrand, NavbarContent, NavbarItem, Spacer } from '@nextui-org/react';
import Link from 'next/link';
import React from 'react';

const NavigationBar: React.FC = () => {
  return (
    <Navbar className="bg-primary bg-opacity-80 text-primary-foreground w-full" maxWidth="full">
      <NavbarBrand>
        <p> Qualia Travel Management </p>
      </NavbarBrand>
      <NavbarContent justify="center">
        <NavbarItem><Link color="foreground" href="/welcome">Home</Link></NavbarItem>
        <NavbarItem><Link color="foreground" href="/about">About</Link></NavbarItem>
        <NavbarItem><Link color="foreground" href="/pricing">Pricing</Link></NavbarItem>
        <NavbarItem><Link color="foreground" href="/contact">Contact</Link></NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem className="hidden lg:flex">
          <SignedIn>
            <ClerkLoading>Loading ...</ClerkLoading>
            <SignOutButton>
            <Button as={Link} href="#" color="secondary" variant="flat">
                Sign Out
                </Button>
            </SignOutButton>
          </SignedIn>
          <SignedOut>
            <SignInButton>
              <Button as={Link} href="#" color="secondary" variant="flat">
                Sign In
              </Button>
            </SignInButton>
            <Spacer x={2} />
            <SignUpButton>
              <Button as={Link} href="#" color="secondary" variant="flat">
                Sign Up
              </Button>
            </SignUpButton>
          </SignedOut>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};

export default NavigationBar;