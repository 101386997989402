'use client';

import NavigationBar from '@/components/ui/NavigationBar';
import { ReactNode } from 'react';
import Footer from './_components/footer';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="min-h-screen flex flex-col bg-background text-foreground dark:bg-dark-primary dark:text-dark-primary-foreground">
      {/* Navigation Bar */}
      <NavigationBar />

      {/* Main Content */}
      <main className="container mx-auto px-4 py-16 flex-grow overflow-y-auto">
        {children}
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Layout;
